import React from "react";
import { Helmet } from "react-helmet";

const title =
  "New Zealand Wines wins Independent Retailer of the Year | Articles | Manchester Students Group";
const description = "";

export default function FreshersWeekDoAndDonts(p) {
  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <div className="blog-post">
        <img src="/wine.jpg" alt="" />
        <h1>New Zealand Wines wins Independent Retailer of the Year</h1>
        <p>Guide / 2 minute read</p>

        <p>
          On the 2nd of November 2023, our local heroes were finally nationally
          recognised as they received the Independent Retailer of the Year
          award, beating out competition from all across the UK.
        </p>

        <p>
          The classy affair, held at none other than the London Marriot Hotel on
          Park Lane, was held by Bestway Wholesale Group, with the award
          formally called the ‘Bestway Performance Award’.
        </p>

        <p>
          And who can truly be surprised - NZW is a shining pillar of the
          Fallowfields community, famous for its 2 for £5 bottle of wine (RIP),
          booming club music while you shop, ungodly opening times and friendly
          staff. An unwavering rock on the ever-changing landscape of the
          Fallowfield high street, it has provided easily accessible and
          reasonably priced predrinks for an entire generation of students, and
          will probably outlive us all. There was only ever one winner.
          Congratulations New Zealand Wines!
        </p>
      </div>
    </main>
  );
}
